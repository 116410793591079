#error_area {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
        font-size: 15px;
        font-weight: 700;
        color: var(--theme-text-color);
    }
    h1 {
        font-size: 200px;
        color: var(--theme-title-color);
        font-weight: 700;
    }
    .gotohome {
        color: var(--theme-title-color);
    } 
}