// @import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

:root {
  // ===================== START FONT-WEIGHT ===================== //
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --color-green: #50cd89;
  --bg-success-bg: #e8fff3;
  --color-danger: #f1416c;
  --bg-danger: #fff5f8;
  --border-color: #ddd;
  --theme-fonts: "Open Sans", sans-serif;
  --menu-fonts: "Open Sans", sans-serif;
  --text-secondary: #f000b9;
  --bg-secondary: rgba(240, 0, 185, 0.15);
  // ===================== END FONT-WEIGHT ===================== //

  --theme-bg-1: #4f46e5;
  --theme-bg-3: #edecfc;
  --theme-body-bg: #f8fafc;
  --theme-hover-color: #ff9800;
  --theme-title-color: #334155;
  --theme-text-color: #64748b;
  --theme-border-color: #e2e8f0;
  --theme-border-hover: #adb8c5;
  --theme-nav-tab-color: #e2e8f0;
  --theme-bg-white: #ffffff;
  --primary-button-bg: #4f46e5;
  --primary-button-color: #ffffff;
  --primary-button-hover: #ff9800;
  --primary-button-hover-text: #ffffff;
  --secondary-button-bg: #fff4e5;
  --secondary-button-color: #ff9800;
  --secondary-button-hover: #ff9800;
  --secondary-button-hover-text: #ffffff;
  --box-shadow: 0 3px 10px 0 rgba(48, 46, 56, 0.07);
}

.theme-1 {
  --theme-bg-1: #4f46e5;
  --theme-bg-3: #edecfc;
  --theme-body-bg: #f8fafc;
  --theme-hover-color: #4f46e5;
  --theme-title-color: #334155;
  --theme-text-color: #64748b;
  --theme-border-color: #e2e8f0;
  --theme-border-hover: #adb8c5;
  --theme-nav-tab-color: #e2e8f0;
  --theme-bg-white: #ffffff;
  --primary-button-bg: #4f46e5;
  --primary-button-color: #ffffff;
  --primary-button-hover: #ff9800;
  --primary-button-hover-text: #ffffff;
  --secondary-button-bg: #fff4e5;
  --secondary-button-color: #ff9800;
  --secondary-button-hover: #ff9800;
  --secondary-button-hover-text: #ffffff;
}

.theme-2 {
  --theme-bg-1: #8B5CF6;
  --theme-bg-3: #f3eefe;
  --theme-body-bg: #F1F5F9;
  --theme-hover-color: #8B5CF6;
  --theme-title-color: #1E293B;
  --theme-text-color: #64748B;
  --theme-border-color: #E2E8F0;
  --theme-border-hover: #adb8c5;
  --theme-nav-tab-color: #E2E8F0;
  --theme-bg-white: #ffffff;
  --primary-button-bg: #8B5CF6;
  --primary-button-color: #ffffff;
  --primary-button-hover: #F9896B;
  --primary-button-hover-text: #ffffff;
  --secondary-button-bg: #fef3f0;
  --secondary-button-color: #F9896B;
  --secondary-button-hover: #F9896B;
  --secondary-button-hover-text: #ffffff;
}

.theme-3 {
  --theme-bg-1: #00A5A2;
  --theme-bg-3: #e5f6f6;
  --theme-body-bg: #F4F6F8;
  --theme-hover-color: #00A5A2;
  --theme-title-color: #111111;
  --theme-text-color: #333333;
  --theme-border-color: #E9EAEC;
  --theme-border-hover: #adb8c5;
  --theme-nav-tab-color: #E9EAEC;
  --theme-bg-white: #ffffff;
  --primary-button-bg: #00A5A2;
  --primary-button-color: #ffffff;
  --primary-button-hover: #F4A74D;
  --primary-button-hover-text: #ffffff;
  --secondary-button-bg: #fef6ed;
  --secondary-button-color: #F4A74D;
  --secondary-button-hover: #F4A74D;
  --secondary-button-hover-text: #ffffff;
}

.theme-4 {
  --theme-bg-1: #013961;
  --theme-bg-3: #e5ebef;
  --theme-body-bg: #F3F6F9;
  --theme-hover-color: #013961;
  --theme-title-color: #333335;
  --theme-text-color: #8C9097;
  --theme-border-color: #E2E8F0;
  --theme-border-hover: #adb8c5;
  --theme-nav-tab-color: #E4EBF1;
  --theme-bg-white: #ffffff;
  --primary-button-bg: #013961;
  --primary-button-color: #ffffff;
  --primary-button-hover: #00A5A2;
  --primary-button-hover-text: #ffffff;
  --secondary-button-bg: #e5f6f6;
  --secondary-button-color: #00A5A2;
  --secondary-button-hover: #00A5A2;
  --secondary-button-hover-text: #ffffff;
}

.theme-5 {
  --theme-bg-1: #1F76BA;
  --theme-bg-3: #e8f1f8;
  --theme-body-bg: #F2F3F7;
  --theme-hover-color: #1F76BA;
  --theme-title-color: #000025;
  --theme-text-color: #848399;
  --theme-border-color: #E2E8F0;
  --theme-border-hover: #adb8c5;
  --theme-nav-tab-color: #EBECF2;
  --theme-bg-white: #ffffff;
  --primary-button-bg: #1F76BA;
  --primary-button-color: #ffffff;
  --primary-button-hover: #DE562B;
  --primary-button-hover-text: #ffffff;
  --secondary-button-bg: #fceee9;
  --secondary-button-color: #DE562B;
  --secondary-button-hover: #DE562B;
  --secondary-button-hover-text: #ffffff;
}

.theme-6 {
  --theme-bg-1: #F47080;
  --theme-bg-3: #fef0f2;
  --theme-body-bg: #F9F9F9;
  --theme-hover-color: #F47080;
  --theme-title-color: #272727;
  --theme-text-color: #5B5B5B;
  --theme-border-color: #F0F0F0;
  --theme-border-hover: #adb8c5;
  --theme-nav-tab-color: #F0F0F0;
  --theme-bg-white: #ffffff;
  --primary-button-bg: #F47080;
  --primary-button-color: #ffffff;
  --primary-button-hover: #272727;
  --primary-button-hover-text: #ffffff;
  --secondary-button-bg: #e9e9e9;
  --secondary-button-color: #272727;
  --secondary-button-hover: #272727;
  --secondary-button-hover-text: #ffffff;
}

.theme-7 {
  --theme-bg-1: #1F2382;
  --theme-bg-3: #e8e9f2;
  --theme-body-bg: #F9F9F9;
  --theme-hover-color: #1F2382;
  --theme-title-color: #1F2382;
  --theme-text-color: #151515;
  --theme-border-color: #E6E6E6;
  --theme-border-hover: #adb8c5;
  --theme-nav-tab-color: #E6E6E6;
  --theme-bg-white: #ffffff;
  --primary-button-bg: #1F2382;
  --primary-button-color: #ffffff;
  --primary-button-hover: #F0DE36;
  --primary-button-hover-text: #1F2382;
  --secondary-button-bg: #fdfae1;
  --secondary-button-color: #1F2382;
  --secondary-button-hover: #F0DE36;
  --secondary-button-hover-text: #1F2382;
}

body {
  margin: 0;
  font-family: var(--theme-fonts);
  box-sizing: border-box;
  background-color: var(--theme-body-bg);
  font-size: 13px;
  color: var(--theme-text-color);

  &.modal-open {
    overflow: hidden !important;
  }
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: var(--theme-title-color);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid var(--primary-button-bg);
  border-radius: 100%;
  background: var(--theme-bg-white);
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--primary-button-bg);
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

::-webkit-scrollbar-thumb {
  background-color: #ddd;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-track {
  border-radius: 0.5rem;
}

.fw-900 {
  font-weight: 900;
}

.f-35 {
  font-size: 35px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-2 {
  margin-left: 10px !important;
}

.ml-3 {
  margin-left: 15px !important;
}

.mr-2 {
  margin-right: 10px !important;
}

.mr-3 {
  margin-right: 15px !important;
}

.ml-15px {
  margin-left: 15px;
}

.mb-15px {
  margin-bottom: 15px;
}

.mt-25px {
  margin-top: 25px;
}

.my-5px {
  margin-top: 5px;
  margin-bottom: 5px;
}

.pb-15px {
  padding-bottom: 15px;
}

.mx-7 {
  margin: 0 -7.5px;
}

.px-7 {
  padding: 0 7.5px;
}

.badge {
  border-radius: 0 !important;
}

body::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--theme-title-color);
  border-radius: 7px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.w-60 {
  width: 60%;
}

.w-20 {
  width: 20%;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent;
}

.form-control {
  display: block;
  width: 100%;
  padding: 8px 14px;
  font-size: 13px;
  color: var(--theme-title-color);
  background-color: var(--theme-bg-white);
  border-radius: 4px;
  box-shadow: none;
  border-color: var(--theme-border-color);

  &.is-valid {
    border-color: var(--theme-border-color);
    padding-right: calc(24px + 24.8px);
    background-image: url("./Assets/Images/greenAroowright.svg");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.3875rem) center;
    background-size: calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);

    &:focus {
      box-shadow: none;
      border-color: var(--theme-border-hover);
    }
  }

  &.is-invalid {
    border-color: #e2e8f0 !important;
    padding-right: calc(24px + 24.8px);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.3875rem) center;
    background-size: calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);

    &:focus {
      box-shadow: none;
      border-color: var(--theme-border-hover);
    }
  }

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--theme-border-hover);
    background-color: var(--theme-bg-white);
    color: var(--theme-title-color);
  }

  &::placeholder {
    color: var(--theme-text-color);
  }

  &:disabled {
    background-color: var(--theme-bg-white);
    cursor: not-allowed;

    &:hover {
      border: 1px solid #e2e8f0;
    }
  }
}

.form-select {
  display: block;
  width: 100%;
  padding: 8px 14px;
  font-size: 13px;
  color: var(--theme-title-color);
  background-color: var(--theme-bg-white);
  border-radius: 4px;
  box-shadow: none;
  border-color: var(--theme-border-color);
}

.filterlable {
  font-size: 14px;
  font-weight: var(--fw-600);
  color: var(--theme-title-color);
}

.ckEditor {
  .ck-editor {
    .ck {
      &.ck-editor__editable_inline {
        border: 1px solid var(--theme-border-hover);
        background-color: var(--theme-bg-white);
        min-height: 150px;
      }

      &.ck.ck-toolbar {
        border: 1px solid var(--theme-border-hover);
        border-bottom: 0;
      }
    }
  }
}

.addAddressFrom-details {

  .css-13cymwt-control,
  .css-t3ipsp-control {
    border-color: var(--theme-border-color);
    min-height: auto;
    box-shadow: none;
    cursor: pointer;

    &>div:first-child {
      padding: 7px 14px 7px 14px;

      .css-1jqq78o-placeholder {
        color: var(--theme-text-color);
      }
    }

    .css-1u9des2-indicatorSeparator {
      display: none;
    }

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: var(--theme-border-hover);
      background-color: var(--theme-bg-white);
    }
  }

  .input-group {

    .css-13cymwt-control,
    .css-t3ipsp-control {
      border-radius: 4px 0px 0 4px;
    }
  }
}

.PhoneCode_No {
  .form-control {
    &.dropdown-width {
      border-radius: 5px 0 0 5px;

      &:hover,
      &:focus {
        border-color: #e2e8f0;
      }
    }
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.container {
  max-width: 1440px;
}

.no_record_text {
  font-size: 20px;
  color: var(--theme-title-color);
  font-weight: bold;
  margin-top: 10px;
}

.diamond_info_title {
  font-size: 16px;
  font-weight: var(--fw-600);
  border-bottom: 1px solid var(--theme-border-color);
  background-color: var(--theme-body-bg);
  padding: 8px;
  color: var(--theme-title-color);
}

#pagination {
  padding-bottom: 5px;
}

.diamond-loader {
  min-width: 200px;
  height: 100%;
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s linear;
  width: 100%;

  &.ModalLoader {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;

    .loader-1 {
      border-color: #fff #fff transparent;
    }
  }

  .loader-1 {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 2px solid;
    border-color: #64748b #64748b transparent;
    -webkit-animation: rotation 1s linear infinite;
    animation: rotation 1s linear infinite;
  }
}

// * keyFrames */
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#mainPartOFpos {
  position: relative;

  .HomepageConatin {
    margin-left: 288px;
    transition: 0.5s ease all;
    margin-top: 65px;

    @media screen and (max-width: 1200px) {
      margin-left: 230px;
    }

    @media screen and (max-width: 768px) {
      margin-left: 0;
      margin-top: 52px;
    }
  }

  .dashboeader {
    padding: 25px 35px;
    background-color: var(--theme-body-bg);
    min-height: calc(100vh - 108px);
    position: relative;

    @media screen and (max-width: 1500px) {
      padding: 20px;
    }
  }
}

.lab_grown_title {
  color: var(--theme-title-color);
  font-weight: var(--fw-600);
  font-size: 14px;
  margin-bottom: 5px;
}

.diamondsforapurpose_jewellery {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;

  &.no_record_found {
    display: flex;
  }

  .product-boxes {
    width: calc(100% / 5 - 16px);
    margin: 8px;
    position: relative;

    .wishlist-wrapper {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 20px;
      line-height: 1;
      color: var(--theme-title-color);
      cursor: pointer;
      z-index: 9;
      padding: 10px;
    }
  }

  .card {
    position: relative;
    border-radius: 5px;
    transition: 0.3s ease all;
    border: 1px solid transparent;
    cursor: pointer;
    background-color: transparent;
    overflow: hidden;
    box-shadow: var(--box-shadow);

    .card-img-top {
      overflow: hidden;

      .figure {
        img {
          object-fit: scale-down;
          transition: all 0.5s ease;
        }
      }
    }

    .card-body {
      padding: 10px;
      background-color: var(--theme-bg-white);

      .card-title {
        color: var(--theme-title-color);
        font-weight: var(--fw-500);
        font-size: 14px;
        margin: 0 0 8px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        letter-spacing: 0.4px;
      }

      .card-text {
        color: var(--theme-text-color);
        font-weight: var(--fw-500);
        font-size: 13px;
      }
    }

    &:hover {
      border-color: var(--theme-text-color);
    }
  }

  @media screen and (max-width: 1366px) {
    .product-boxes {
      width: calc(100% / 4 - 16px);
    }
  }

  @media screen and (max-width: 991px) {
    .product-boxes {
      width: calc(100% / 3 - 16px);
    }
  }

  @media screen and (max-width: 575px) {
    margin: 0 -6px;

    .product-boxes {
      width: calc(100% / 2 - 12px);
      margin: 6px;
    }

    .card {
      .card-body {
        padding: 5px;

        .card-title {
          font-size: 13px;
        }

        .card-text {
          font-size: 12px;
        }
      }
    }

  }
}

.tooltip {
  transition: opacity 400ms linear;
  opacity: 0;

  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    background-color: var(--primary-button-bg);
    color: var(--primary-button-color);
    font-weight: 500;
    font-size: 14px;
  }

  .tooltip-arrow {
    &::before {
      border-bottom-color: var(--primary-button-bg);
    }
  }
}

.OpenSidebar .tooltip {
  display: none;
}

.btn-close {
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

a {
  text-decoration: none;
}

ul {
  li {
    list-style: none;
  }
}

.w-10 {
  width: 10%;
}

.offcanvas.offcanvas-end {
  border: 0;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

// ! Buttons
.btn {
  box-shadow: none;
  padding: 8px 14px;
  min-width: auto;
  font-weight: var(--fw-600);
  font-size: 13px;
  letter-spacing: 0.025em;
  outline: none;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 17px;
  }
}

.normal-btn {
  background-color: transparent;
  font-size: 15px;
  font-weight: 500;
  padding: 0;
  border: none;
  border-bottom: 1px solid transparent;
  color: var(--theme-title-color);
  outline: none;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid var(--theme-title-color);
  }

  i {
    font-size: 11px;
    font-weight: 600;
    margin-right: 8px;
  }
}

.primary-button {
  background-color: var(--primary-button-bg);
  color: var(--primary-button-color);
  border-color: var(--primary-button-bg);

  &:hover {
    background-color: var(--primary-button-hover);
    border-color: var(--primary-button-hover);
    color: var(--primary-button-hover-text);
  }

  &:focus {
    background-color: var(--primary-button-hover);
    border-color: var(--primary-button-hover);
    color: var(--primary-button-hover-text);
  }

  &:active {
    background-color: var(--primary-button-bg);
    color: var(--primary-button-color);
    border-color: var(--primary-button-hover-text);
  }
}

.default-button {
  background-color: var(--theme-bg-white);
  color: var(--theme-title-color);

  &:hover,
  &:focus {
    background-color: var(--theme-bg-white);
    color: var(--theme-title-color);
    opacity: 0.8;
  }
}

.secondary-button {
  background-color: rgba(255, 152, 0, 0.1);
  color: #ff9800;
  border: 0;

  &:hover {
    background-color: rgba(255, 152, 0, 0.2);
    color: #ff9800;
  }

  &:focus {
    background-color: rgba(255, 152, 0, 0.3);
  }

  &:active {
    background-color: gba(255, 152, 0, 0.3) !important;
    color: #ff9800 !important;
  }
}

.danger-button {
  background-color: transparent;
  color: #ff6c6c;
  border-color: #ff6c6c;

  &:hover {
    background-color: #ff6c6c;
    border-color: #ff6c6c;
    color: #ffffff;
  }
}

.info-button {
  background-color: transparent;
  color: var(--primary-button-bg);
  border-color: var(--primary-button-bg);

  &:hover {
    background-color: var(--primary-button-bg);
    border-color: var(--primary-button-bg);
    color: var(--primary-button-color);
  }
}

.cart-button {
  background-color: var(--theme-nav-tab-color);
  border-color: var(--theme-nav-tab-color);
  color: var(--theme-title-color);

  &:hover,
  &:focus {
    background-color: var(--theme-nav-tab-color);
    border-color: var(--theme-nav-tab-color);
    color: var(--theme-title-color);
  }
}

.diamond_active {
  background-color: var(--primary-button-hover);
  color: var(--primary-button-hover-text);
}

.btn-black {
  background-color: #000;
  color: #fff;
  border-color: #000;
  box-shadow: none;

  &:hover,
  &:focus {
    color: #fff;
    background-color: #000;
    border-color: #000;
    box-shadow: none;
  }
}

.close-icon {
  position: absolute;
  top: 3px;
  right: 3px;
  color: red;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-bg-white);
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #e2e8f0;

  i {
    font-weight: 700;
    font-size: 12px;
  }
}

.upload-img-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;

  .upload-img-col {
    margin: 5px;
    width: 120px;
    border: 1px solid #e2e8f0;
    display: flex;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    background-color: var(--theme-bg-white);
  }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: var(--theme-title-color);
  border-radius: 4px;
  background-size: 20px;
}

.file-upload {
  position: relative;
  border: 1px dashed rgb(168, 168, 168);
  border-radius: 4px;
  background-color: var(--theme-bg-white);
  padding: 10px;
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--theme-title-color);
  cursor: pointer;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.file-upload>input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.f-30 {
  font-size: 30px;
}

.btn-danger {
  background-color: var(--bg-danger);
  color: var(--text-white);
  border-color: var(--text-danger);
  box-shadow: none;

  &:hover,
  &:focus {
    opacity: 0.9;
  }
}

.inventory_tab {
  background-color: var(--theme-nav-tab-color);
  border-radius: 5px;
  padding: 4px;
  box-shadow: var(--box-shadow);
  border: 0;

  .nav-item {
    overflow: hidden;

    .nav-link {
      border: 0;
      border-radius: 5px;
      color: var(--theme-title-color);
      font-size: 14px;
      font-weight: var(--fw-600);
      padding: 6px 12px;
      margin-bottom: 0;
      letter-spacing: 0.025em;
      line-height: 20px;
      outline: none;
      box-shadow: none;

      &.active {
        background-color: var(--theme-bg-white);
        color: var(--theme-title-color);
      }

      &.disabled {
        opacity: 0.6;
        background-color: transparent;
        pointer-events: auto;
        cursor: not-allowed;
      }

      .tab-icon {
        font-size: 22px;
      }
    }

    &+.nav-item {
      margin-left: 5px;
    }
  }
}

.d_flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.DiamondFilter {
  background-color: var(--theme-bg-white);
  padding: 15px 20px 5px;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
}

.fluorescence-ul {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  gap: 5px;
  margin: 0;

  .maxWidth {
    min-width: 100px;
  }

  li {
    list-style: none;

    .images-li {
      .fluorescence-images {
        padding: 10px;
        display: flex;
        overflow: hidden;
        align-items: baseline;
        justify-content: center;
        transition: 0.3s ease-in-out all;
        border-width: 1px 1px 0px 1px;
        border-style: solid;
        border-radius: 4px 4px 0px 0px;
        border-color: var(--theme-title-color);

        &+.fluorescence-li {
          border-radius: 0 0 4px 4px;
        }

        &.active {
          border-color: var(--primary-button-hover);
        }
      }
    }

    .fluorescence-li {
      padding: 8px 10px;
      cursor: pointer;
      text-align: center;
      position: relative;
      color: var(--theme-text-color);
      transition: 0.3s ease-in-out all;
      border: 1px solid var(--theme-border-color);
      font-weight: var(--fw-500);
      border-radius: 4px;

      &:hover {
        background-color: var(--theme-bg-3);
        color: var(--primary-button-bg);
        border-color: var(--primary-button-bg);
      }

      .color-code {
        top: 0;
        left: 0;
        width: 7px;
        height: 100%;
        position: absolute;
        border-radius: 4px 0 0 4px;
        border-width: 1px;
        border-style: solid;
      }
    }

    .fluorescence-li-disabled-property {
      opacity: 0.5;
      position: relative;
      cursor: not-allowed;
      pointer-events: none;

      &:after {
        background: linear-gradient(to top right, transparent calc(50% - 0.5px), hsla(0deg, 1%, 65%, 0.6), hsla(0deg, 1%, 65%, 0.6), transparent calc(50% + 0.5px));
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: all 0.2s ease-in-out;
        width: 100%;
        z-index: 2;
      }
    }

    .fluorescence-li.active {
      border: 1px solid var(--primary-button-bg);
      color: var(--primary-button-bg);
      background-color: var(--theme-bg-3);
    }
  }

  @media screen and (min-width: 1200px) {
    li {
      min-width: calc(100% / 18);
    }

    .maxWidth {
      min-width: 110px;
    }
  }

  @media screen and (max-width: 1200px) {
    li {
      min-width: calc(100% / 7);
    }
  }

  @media screen and (max-width: 767px) {
    li {
      min-width: auto;

      .fluorescence-li {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }
  }

  &.DiamondShape {
    li {
      min-width: inherit;
      width: 62px;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;

      .fluorescence-li {
        i {
          font-size: 40px;
        }

        &.fluorescence-li-disabled-property {
          &::after {
            background: linear-gradient(to top right,
                transparent calc(50% - 0.5px),
                hsla(0, 1%, 65%, 0.6),
                hsla(0, 1%, 65%, 0.6),
                transparent calc(50% + 0.5px));
            content: "";
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transition: all 0.2s ease-in-out;
            width: 100%;
            z-index: 2;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      li {
        width: 60px;
        height: 57px;

        .fluorescence-li {
          width: 100%;

          i {
            font-size: 36px;
          }
        }
      }
    }
  }
}

.fluorescence-ul-adva {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  .maxWidth {
    min-width: 100px;
  }

  li {
    cursor: pointer;
    list-style: none;

    .images-li {
      &:hover {
        background-color: var(--theme-body-bg);
      }

      .fluorescence-images {
        padding: 10px;
        display: flex;
        overflow: hidden;
        align-items: baseline;
        justify-content: center;
        transition: 0.3s ease-in-out all;
        border-width: 1px 1px 0px 1px;
        border-style: solid;
        border-radius: 4px 4px 0px 0px;
        border-color: var(--theme-title-color);

        &+.fluorescence-li {
          border-radius: 0 0 4px 4px;
        }

        &.active {
          border-color: var(-theme-btn-primary-hover-color);
        }
      }

      &.master_stone_shape {
        min-width: 75px;

        .fluorescence-li {
          padding: 5px;
        }
      }
    }

    .fluorescence-li {
      padding: 7px 3px;
      cursor: pointer;
      text-align: center;
      position: relative;
      color: var(--theme-text-color);
      transition: 0.3s ease-in-out all;
      border: 1px solid #e2e8f0;
      font-weight: var(--fw-500);
      border-radius: 5px;
      font-size: 12px;

      &:hover {
        background-color: var(--theme-bg-3);
        color: var(--theme-hover-color);
      }

      .color-code {
        top: 0;
        left: 0;
        width: 7px;
        height: 100%;
        position: absolute;
        border-radius: 5px 0 0 5px;
        border-width: 1px;
        border-style: solid;
      }

      i {
        display: block;
        font-size: 32px;
        margin-bottom: 2px;
      }
    }

    .fluorescence-li-disabled-property {
      opacity: 0.3;
      position: relative;
      cursor: not-allowed;
      pointer-events: none;
    }

    .fluorescence-li.active {
      border: 1px solid var(--primary-button-hover);
      background-color: var(--primary-button-hover);
      color: var(--primary-button-hover-text);
    }
  }
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

@media only screen and (max-width: 991px) {
  #mainPartOFpos {
    .dashboeader {
      padding: 12px;
    }
  }
}

@media only screen and (max-width: 390px) {
  .btn {
    padding: 8px 10px;
    font-size: 12px;
    line-height: 1.2;
  }

  #mainPartOFpos .dashboeader {
    padding: 12px;
  }
}

.card {
  .card-img-top {
    background-color: var(--theme-bg-white);

    .figure {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;

      span {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }

      img {
        max-height: 100%;
      }
    }
  }
}

.modal-content-dialog {
  overflow: auto;
  height: calc(100vh - 200px);
}

.mb-5px {
  margin-bottom: 5px;
}

.main-title-content {
  color: var(--theme-title-color);
}

.content-color {
  color: var(--theme-text-color);
}

.modal-dialog {
  .modal-content {
    border: 0;
    border-radius: 0.5rem;
    overflow: hidden;

    .modal-header {
      font-size: 16px;
      font-weight: 500;
      color: var(--theme-title-color);
      padding: 15px;
      background-color: var(--theme-bg-white);

      .modal-title {
        font-size: 16px;
        font-weight: 500;
        color: var(--theme-title-color);
      }

      .btn-close {
        color: var(--theme-title-color);
      }
    }

    .modal-body {
      background-color: var(--theme-body-bg);
    }

    .modal-footer {
      padding: 10px 15px;
      border-top: 1px solid var(--theme-border-color);
      background-color: var(--theme-bg-white);
    }
  }
}

table {
  thead {
    tr {
      th {
        &:first-of-type {
          border-radius: 5px 0 0 0;
        }

        &:last-of-type {
          border-radius: 0 5px 0 0;
        }
      }
    }
  }
}

.no_record_found_detail {
  text-align: center;
  width: 100%;
  background-color: var(--theme-bg-white);
  border-radius: 0 0 5px 5px;
  text-transform: capitalize;
  font-size: 15px;
}

.no_record_found {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  height: 100%;
}

// ! Filter
.filter-btn {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 9999px;
  background-color: transparent;
  color: var(--theme-text-color);
  border: none;
  outline: 2px solid transparent;
  margin-left: 10px;

  &:hover {
    background-color: var(--theme-bg-3);
    color: var(--primary-button-bg);
  }

  &.filter-btn-one:hover {
    background-color: var(--theme-bg-3);
    color: var(--primary-button-bg);
  }

  .ic_sort_filter {
    font-size: 20px;
    font-weight: 500;
    vertical-align: middle;
  }

  &.filter-btn-one {
    font-size: 18px;
  }
}

// ! Showing result
.total {
  font-size: 14px;
  font-weight: 500;
}

// ! Flex
.flex-between-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// ! Table
.table-responsive {
  table {
    margin-bottom: 0;
    color: var(--theme-text-color);
    white-space: nowrap;
    letter-spacing: 0.3px;

    thead {
      tr {
        border: 0;

        th {
          font-size: 13px;
          font-weight: 600;
          padding: 10px 12px;
          color: var(--theme-title-color);
          background-color: var(--theme-body-bg);
          text-transform: capitalize;
          white-space: nowrap;
          border: 0;
        }
      }
    }

    tbody,
    tfoot {
      tr {
        border-width: 0 0 1px;
        border-style: solid;
        border-color: var(--theme-body-bg);

        &:hover {
          background-color: #f1f5f9;
          color: var(--theme-title-color);
        }

        td {
          padding: 10px 12px;
          font-size: 12px;
          font-weight: 500;
          border: 0;
        }

        &:last-child {
          border: 0;

          td {
            border-bottom: 0;
          }
        }
      }
    }

    tfoot {
      tr td {
        font-weight: 800;
      }
    }
  }
}

.table-card {
  background-color: var(--theme-bg-white);
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  padding: 0px;
  border: 0;
  overflow: hidden;
}

.table-card {
  padding: 15px;

  .table-bordered {
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid var(--theme-border-color);
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;

    td,
    th {
      border-top: 1px solid var(--theme-border-color);
      padding: 10px;
      text-align: left;

      &:first-child {
        border-left: none;
      }
    }

    th {
      background-color: var(--theme-body-bg);
      border-top: 0;

      &:first-child {
        -moz-border-radius: 5px 0 0 0;
        -webkit-border-radius: 5px 0 0 0;
        border-radius: 5px 0 0 0;
      }

      &:last-child {
        -moz-border-radius: 0 5px 0 0;
        -webkit-border-radius: 0 5px 0 0;
        border-radius: 0 5px 0 0;
      }

      &:only-child {
        -moz-border-radius: 5px 5px 0 0;
        -webkit-border-radius: 5px 5px 0 0;
        border-radius: 5px 5px 0 0;
      }
    }
  }
}

// ! Tabs
.tab-first {
  background-color: var(--theme-nav-tab-color);
  border-radius: 5px;
  padding: 4px;
  //  box-shadow: var(--box-shadow);
  border: 0;

  .nav-item {
    overflow: hidden;

    .nav-link {
      border: 0;
      border-radius: 5px;
      color: var(--theme-title-color);
      font-size: 14px;
      font-weight: var(--fw-600);
      padding: 6px 12px;
      margin-bottom: 0;
      letter-spacing: 0.025em;
      line-height: 20px;
      outline: none;
      box-shadow: none;

      &.active {
        background-color: var(--theme-bg-white);
        color: var(--theme-title-color);
        outline: none;
      }
    }
  }
}

.click-disable {
  pointer-events: none !important;
  cursor: no-drop !important;
}

.customizerBtn {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 9;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 24px;
  color: var(--primary-button-color);
  background-color: var(--primary-button-bg);
  cursor: pointer;

  i {
    transition: all .15s ease-in-out;
    animation: anim-rotate 2.5s linear infinite;
  }

  &:hover {
    color: var(--primary-button-hover-text);
    background-color: var(--primary-button-hover);
  }

  @keyframes anim-rotate {
    0% {
      transform: rotate(0deg)
    }

    to {
      transform: rotate(1turn)
    }
  }
}

.theme-color {
  display: grid;
  gap: .875rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  a {
    background-color: var(--theme-bg-white);
    border: 2px solid var(--theme-border-color);
    border-radius: 5px;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    height: 56px;
    padding: 4px;

    .circle-box {
      height: 100%;
      overflow: hidden;
      border-radius: 5px;

      .colors-box {
        margin-left: -8px;
        margin-right: -8px;
        display: flex;
        gap: .25rem;
        align-items: center;
        height: 100%;
      }

    }

    .theme-circle {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;
      transition: all 0.15s ease-in-out;
      width: 33.33%;
      height: 200%;
      margin: 0;
      transform: translate(0, 0) rotate(12deg) skew(0) skewY(0) scaleX(1) scaleY(1);
    }

    &[data-value=theme-1] {
      .theme-circle {
        &:first-child {
          background-color: #4f46e5;
        }

        background-color: #e2e8f0;

        &:last-child {
          background-color: #ff9800;
        }
      }
    }

    &[data-value=theme-2] {
      .theme-circle {
        &:first-child {
          background-color: #8B5CF6;
        }

        background-color: #e9eaec;

        &:last-child {
          background-color: #f4a74d;
        }
      }
    }

    &[data-value=theme-3] {
      .theme-circle {
        &:first-child {
          background-color: #00a5a2;
        }

        background-color: #E2E8F0;

        &:last-child {
          background-color: #F9896B;
        }
      }
    }

    &[data-value=theme-4] {
      .theme-circle {
        &:first-child {
          background-color: #013961;
        }

        background-color: #e4ebf1;

        &:last-child {
          background-color: #00a5a2;
        }
      }
    }

    &[data-value=theme-5] {
      .theme-circle {
        &:first-child {
          background-color: #1f76ba;
        }

        background-color: #ebecf2;

        &:last-child {
          background-color: #de562b;
        }
      }
    }

    &[data-value=theme-6] {
      .theme-circle {
        &:first-child {
          background-color: #F47070;
        }

        background-color: #DFDFDF;

        &:last-child {
          background-color: #272727;
        }
      }
    }

    &[data-value=theme-7] {
      .theme-circle {
        &:first-child {
          background-color: #1F2382;
        }

        background-color: #DFDFDF;

        &:last-child {
          background-color: #F0DE36;
        }
      }
    }

    &.active {
      border-color: #000;
    }
  }
}

.customizer-modal {

  .offcanvas-body,
  .offcanvas-header {
    background-color: var(--theme-bg-white);
    border-bottom: 1px solid var(--theme-border-color);
  }

  .theme-title {
    color: var(--theme-title-color);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .Offcanvas-footer {
    padding: 10px 16px;
    background-color: var(--theme-bg-white);
  }
}

.d-none {
  display: none;
  opacity: 0;
}

.fs-12px {
  font-size: 12px;
}

.no_record {
  justify-content: center;
  font-size: 18px;
}