.loader {
  position: fixed;
  left: 288px;
  width: calc(100% - 288px);
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  animation: bg 0.1s;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  transition: 0.3s ease-in-out;

  * {
    pointer-events: none;
  }
}

.directLogin {
  .loader {
    width: 100%;
    left: 0;
  }
}

@media (max-width: 1200px) {
  .body-hidden .loader {
    left: 230px;
    width: calc(100% - 230px);
  }
}

@media (max-width: 768px) {
  #body .loader {
    left: 0;
    width: 100%;
  }
}

.journey-loader {
  width: 100% !important;
  left: 0 !important;
}