#MainFooter {
    background-color: var(--theme-bg-white);
    box-shadow: var(--box-shadow);
    padding: 10px 12px;
    .footer-content {
        text-align: center;
        p {
            font-size: 14px;
            color: var(--theme-text-color);
            margin: 0;
            span {
                font-size: 15px;
                color: var(--theme-title-color);
                cursor: pointer;

                &:hover {
                    color: var(--theme-hover-color);
                }
            }
        }
    }
}
