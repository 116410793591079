#MainHeader {
    position: fixed;
    top: 0;
    left: 288px;
    z-index: 97;
    width: calc(100vw - 288px);
    background-color: var(--theme-bg-white );
    border-bottom: 1px solid var(--theme-border-color);
    @media screen and (max-width: 1200px) {
        left: 230px;
        width: calc(100vw - 230px);
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        left: 0px;
    }

    .HeaderNavbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 24px;
        height: 65px;

        .Pos_text {
            font-size: 20px;
            color: var(--theme-title-color);
            font-weight: var(--fw-600);
            .sidebarControl{
                display: none;
                @media screen and (max-width: 768px) {
                    display: block;
                }
            }
        }
        .Navbar {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: 150px;
                max-height: 100%;

                &.logowhite {
                    filter: brightness(0) invert(1);
                }
            }
        }
        @media screen and (max-width: 768px) {
            padding: 10px 12px; 
            height: auto;
            .Pos_text,  .Navbar, .HeaderProfile{
                flex: 1;
            }
            .Navbar{
                img{
                    max-width: 120px;
                    max-height: 40px;
                }
            }
        }
    }

    .HeaderProfile {
        display: flex;
        gap: 6px;
        justify-content: end;

        .ProfileBar {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
            a {
                position: relative;
                color: var(--theme-title-color);
                outline: none;
            }

            i {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 22px;
                transition: 0.3s ease all;
                padding: 5px;
            }

            .count {
                position: absolute;
                top: -8px;
                right: 10px;
                background-color: var(--primary-button-bg);
                width: 20px;
                height: 20px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--primary-button-color);
                transform: translate(70%, 0%);
                font-size: 11px;
            }

            img {
                border-radius: 8px;
                height: 40px;
                width: 40px;
                cursor: pointer;
            }
            &.active,
            &:hover {
                background-color: var(--theme-bg-3);
                border-radius: 100%;
                &>a {
                    color: var(--primary-button-bg);

                    i {
                        color: var(--primary-button-bg);
                    }
                }
            }
        }
    }
}