.toast-header > button {
    color: #fff !important;
    box-shadow: none;

    &:focus {
        box-shadow: none;
    }
}

.toast {
    display: flex;
    .toast-body {
        flex: 1;
        padding: 16px;
        .toast-btn{
            padding: 0;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: none;
            border: 0;
        }
        .toster-content {
            display: flex;
            i {
                vertical-align: middle;
                margin-right: 8px;
                font-size: 22px;
                font-weight: 500;
            }
        }
    }
}
.w-1 {
    width: 0.375rem;
}
