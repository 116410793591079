#SideBar {
    #MainSideBar {
        position: relative;
        .Navbar{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 96px;
            background-color: var(--primary-button-bg);
            
            .text {
                position: absolute;
                color: var(--primary-button-color);
                font-size: 24px;
                font-weight: 700;
            }
        }
        .main-profile {
            padding: 0px 15px;
            display: flex;
            align-items: center;
            img{
                width: 60px;
                height: 60px;
                object-fit: cover;
                border-radius: 100px;
                border: 2px solid #f5f5f5;
                margin-top: -20px;
                min-width: 60px;
            }
            .profile-icon {
                border: 2px solid #f5f5f5;
                border-radius: 100px;
                height: 60px;
                margin-top: -20px;
                min-width: 60px;
                object-fit: cover;
                width: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 30px;
                background-color: #fff;
            }
            .user-details {
                padding-left: 6px;
                padding-top: 4px;
                .user-name {
                    font-size: 18px;
                    color: var(--theme-title-color);
                    font-weight: 600;
                }
                .user-email {
                    font-size: 12px;
                }
            }
        }
        .profile-line {
            background-color: var(--theme-nav-tab-color);
            margin: 16px 20px;
            height: 1px;
        }
        
        
        .logoDashboard {
            height: 100%;
            background-color: var(--theme-bg-white);

            @media screen and (max-width: 768px) {
                display: block;
            }
        }
    }

    #MainSideBar1 {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 99;
        width: 288px;
        height: 100%;
        background-color: var(--theme-bg-white);
        transition: 0.3s ease all;
        .logout-menu {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 9;
            width: 100%;
            right: 0;
            background-color: var(--theme-bg-white);
            border-radius: 5px;
            .SidebarMenu {
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--secondary-button-color);
                background-color: var(--secondary-button-bg);
                padding: 10px 20px;
                max-width: 80%;
                margin: 0 auto 20px;
                border-radius: 5px;
                cursor: pointer;
                i{
                    font-size: 22px;
                }
                span{
                    margin-left: 10px;
                    font-size: 14px;
                    margin-bottom: 0;
                    font-weight: var(--fw-500);
                    letter-spacing: .025em;
                }
                &:hover{
                    background-color: var(--secondary-button-hover);
                    color: var(--secondary-button-hover-text);
                }
            }
        }
        .sidebarscroll {
            height: calc(100vh - 250px);
            overflow-y: auto;
            overflow-x: hidden;
            padding: 10px 20px;
            &::-webkit-scrollbar {
                width: 2px;
            }
            .SidebarMenu {
                display: flex;
                align-items: center;
                padding: 10px 20px;
                border-radius: 5px;
                cursor: pointer;
                text-decoration: none;
                margin-bottom: 6px;
                color: var(--theme-title-color);
                transition: 0.3s ease all;
                font-family: var(--menu-fonts);

                .ic_chevron_right {
                    font-size: 12px;
                    margin-left: auto;

                    &.active {
                        opacity: 1;
                    }
                }

                i {
                    font-size: 22px;
                }

                span {
                    margin-left: 10px;
                    font-size: 14px;
                    margin-bottom: 0;
                    letter-spacing: .025em;
                    font-weight: var(--fw-500);

                    &.active {
                        opacity: 1;
                    }
                }
                &:hover {
                    background-color: var(--theme-bg-3);
                    color: var(--primary-button-bg); 
                }
                &.active {
                    background-color: var(--primary-button-bg);
                    color: var(--primary-button-color);
                }
                
            }
        }
        @media screen and (max-width: 1200px){
            width: 230px;
            .main-profile {
                padding: 0px 10px;
                img{
                    width: 50px;
                    height: 50px;
                    min-width: 50px;
                }
                .user-details {
                    .user-name{
                        font-size: 14px;
                    }
                    .user-email{
                        font-size: 10px;
                    }
                }
            }
            .sidebarscroll{
                padding: 10px 10px;
                .SidebarMenu{
                    padding: 10px 10px;
                }
            }
        }
        @media screen and (max-width: 768px){
            visibility: hidden;
            opacity: 0;
            width: 0;
        }
    }
    .SiderbarMain {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        z-index: 98;
        opacity: 0.2;
        @media screen and (max-width: 768px) {
            background-color: #000;
        }
    }
}
.OpenSidebar{
    #SideBar {
        #MainSideBar1{
            opacity: 1;
            visibility: visible;
            width: 288px;
        }
    }
}